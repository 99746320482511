import React from 'react'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'
import format from 'date-fns/format'
import AdvancedTable from '../../components/AdvancedTable'
import SmallAvatarWithName from '../../components/SmallAvatarWithName'
import ButtonBase from '../../components/ButtonBase'
import { selectAssignments } from '../../misc/selectors'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import useI18n from '../../misc/languages/useI18n'

const AssignmentsTab = ({ studentId, subject, from, to, schoolId, ...props }) => {
  const theme = useTheme()
  const { t } = useI18n()
  const assignments = useSelector((state) => selectAssignments(state, studentId))
  const history = useHistory()
  const { isSchool } = useSelector((state) => state.firebase.profile)

  const columns = [
    {
      key: 'status',
      label: t('status'),
    },
    isSchool
      ? {
          key: 'teacher',
          label: t('teacher'),
        }
      : null,
    // {
    //   key: 'finishedAt',
    //   label: t('submit time'),
    // },
    {
      key: 'score',
      label: t('score'),
    },
    {
      key: 'grade',
      label: t('grade'),
    },
  ].filter((a) => a)
  const rows = assignments
    .filter(
      (a) =>
        get(a, 'subject.id') === subject &&
        a.createdAt >= from &&
        a.createdAt <= to &&
        (a.school || '') === schoolId
    )
    .map((b) => {
      const finishedAt = get(b, `submissions.${studentId}.finishedAt`, 0)
      const percentage = get(b, `submissions.${studentId}.score`) / b.noOfQuestions
      const grade = get(b, 'subject.grades', []).find(
        (g) => percentage >= g.min && percentage <= g.max
      )
      return {
        key: b.id,
        school: {
          id: b.school || '',
        },
        onClick: () =>
          history.push(
            finishedAt
              ? `/app/assignment-submission/${b.id}/${studentId}`
              : `/app/assignment-result/${b.id}`
          ),
        createdAt: {
          label: format(b.createdAt, 'yyyy-MM-dd'),
          sortValue: b.createdAt,
        },
        status: {
          label: (
            <Typography color={get(b, 'status.color', '')} variant="subtitle2">
              {t(get(b, 'status.label', ''))}
            </Typography>
          ),
          // mobileLabel: (
          //   <Typography color={get(b, 'status.color', '')} variant="h6">
          //     {t(get(b, 'status.label', ''))}
          //   </Typography>
          // ),
          searchValue: t(get(b, 'status.label', '')),
          sortValue: t(get(b, 'status.label', '')),
        },
        teacher: {
          label: <SmallAvatarWithName profile={get(b, 'students[0].teacherProfile', '')} />,
          searchValue:
            get(b, 'students[0].teacherProfile.displayName', '') +
            get(b, 'students[0].schoolProfile.displayName', ''),
          sortValue: get(b, 'students[0].teacherProfile.displayName', ''),
        },
        finishedAt: {
          label: finishedAt ? format(finishedAt, 'yyyy-MM-dd, hh:mm a') : t('not submitted yet'),
          sortValue: finishedAt,
          color:
            finishedAt > b.dueDate || (!finishedAt && Date.now() > b.dueDate) ? 'error' : undefined,
        },
        score: {
          label: `${get(b, `submissions.${studentId}.score`, 0)} / ${b.noOfQuestions} (${Math.round(
            (100 * get(b, `submissions.${studentId}.score`, 0)) / b.noOfQuestions
          )}%)`,
          sortValue: percentage,
        },
        grade: {
          label: get(grade, 'grade', '/'),
          sortValue: percentage,
          color: get(grade, 'color'),
          bold: !!grade,
        },
      }
    })
  return (
    <AdvancedTable
      rows={rows}
      columns={columns}
      defaultSortBy="createdAt"
      defaultSortOrder={false}
      renderMobileItem={(row) => (
        <ButtonBase key={row.key} onClick={row.onClick}>
          <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              {isSchool ? <Box mb={1}>{row.teacher.label}</Box> : null}
              {row.status.label}
              <Box display="flex">
                <Box mr={0.5}>
                  <Typography variant="body2" color="textPrimary">
                    {t('submit time')}:
                  </Typography>
                </Box>
                <Typography variant="body2" color={row.finishedAt.color || 'textSecondary'}>
                  {row.finishedAt.label}
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                {row.score.label}
              </Typography>
            </Box>
            {row.grade.label ? (
              <Box
                bgcolor={row.grade.color}
                borderRadius="50%"
                width={50}
                height={50}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h5" style={{ color: 'white' }}>
                  {row.grade.label}
                </Typography>
              </Box>
            ) : null}
          </Box>
          <Divider />
        </ButtonBase>
      )}
      emptyText={t('no assignment')}
      {...props}
    />
  )
}

export default AssignmentsTab
