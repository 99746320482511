import React from 'react'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import format from 'date-fns/format'
import { useFirestoreConnect } from 'react-redux-firebase'
import AdvancedTable from '../../components/AdvancedTable'
import { selectAdmins } from '../../misc/selectors'
import AdminModal from '../../components/AdminModal'
import TickIcon from '@material-ui/icons/Check'
import SmallAvatarWithName from '../../components/SmallAvatarWithName'
import useI18n from '../../misc/languages/useI18n'

const Admins = () => {
  const { t } = useI18n()
  const [edittingUser, setEdittingUser] = React.useState()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 4)
  const users = useSelector(selectAdmins)
  useFirestoreConnect([{ collection: 'users', where: [['role', 'in', ['admin', 'super-admin']]] }])
  const columns = [
    {
      key: 'displayName',
      label: t('name'),
    },
    {
      key: 'email',
      label: t('email'),
    },
    // {
    //   key: 'createdAt',
    //   label: t('created at'),
    // },
    {
      key: 'superAdmin',
      label: t('super admin'),
    },
  ]
  const rows = users.map((b) => ({
    key: b.id,
    onClick: () => {
      setIsModalOpen(true)
      setEdittingUser(b)
    },
    displayName: {
      label: <SmallAvatarWithName profile={b} />,
      sortValue: b.displayName,
      searchValue: b.displayName,
    },
    email: {
      label: b.email,
    },
    createdAt: {
      label: format(b.createdAt || Date.now(), 'yyyy-MM-dd'),
      sortValue: b.createdAt,
    },
    superAdmin: {
      label: b.role === 'super-admin' ? <TickIcon color="primary" /> : null,
      sortValue: b.role,
      searchValue: b.role,
    },
  }))
  return (
    <WithAppBar title={t('admins')}>
      <AdvancedTable
        containerProps={{ height: minAvailableScreenHeight }}
        rows={rows}
        columns={columns}
        defaultSortBy="createdAt"
        defaultSortOrder={false}
        onAdd={() => {
          setIsModalOpen(true)
          setEdittingUser()
        }}
        emptyText={t('no admin')}
      />
      <AdminModal
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        user={edittingUser}
      />
    </WithAppBar>
  )
}

export default Admins
