import blue from '@material-ui/core/colors/blue'
import pink from '@material-ui/core/colors/pink'

export const primaryColor = blue
export const secondaryColor = pink
export const firebaseConfig = {
  apiKey: 'AIzaSyD_BObnHvVfwnwhgWgWaYqg9IyUfUfNbHA',
  authDomain: 'thinka-v2.firebaseapp.com',
  databaseURL: 'https://thinka-v2.firebaseio.com',
  projectId: 'thinka-v2',
  storageBucket: 'thinka-v2.appspot.com',
  messagingSenderId: '354345713001',
  appId: '1:354345713001:web:5f3e5a5a06fe71213e11f8',
  measurementId: 'G-3ZPZZT4BGD',
}
export const facebookPixelId = '4356310451106932'
export const premiumConfig = {
  freeLimit: {
    questionPerPractice: 20,
    practicePerDay: 3,
    freeTrialDays: 0,
    rateOnAppStoreDays: 7, // 0 indicates disable rate on app store
    shareOnFacebookDays: 7, // 0 indicates disable share on facebook
  },
  plans: {
    student: {
      key: 'price_1Gtwl6KqRQIx065Igygw2RSO',
      price: 79,
    },
    studentLimitedTimeDiscount: {
      key: 'price_1Gtwl6KqRQIx065IavNXV8vX',
      price: 40,
    },
    studentSchoolDiscount: {
      key: 'price_1Gtwl6KqRQIx065IGETbeWme',
      price: 55,
    },
    teacher: {
      key: 'price_1GtwjkKqRQIx065IfJsQt4FT',
      price: 109,
    },
    teacherLimitedTimeDiscount: {
      key: 'price_1GtwjkKqRQIx065I6MMNiNzC',
      price: 55,
    },
    teacherSchoolDiscount: {
      key: 'price_1GtwjkKqRQIx065IMtyds4kq',
      price: 76,
    },
  },
}

export const stripeKey = 'pk_live_1kyUaHz3z2KpFjmhJhpo2b9K00A9HTg99a'

export const maxNoOfQuestions = 40
export const maxTimeLimit = 90
export const slackUrl =
  'https://hooks.slack.com/services/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'
export const availableLanguages = [
  {
    label: 'English',
    value: 'en',
    default: true,
  },
  {
    label: '中文',
    value: 'zh',
  },
]
export const availableRoles = [
  {
    label: 'student',
    value: 'student',
  },
  {
    label: 'parent or teacher',
    value: 'teacher',
  },
  {
    label: 'school',
    value: 'school',
  },
]

export const pricingMode = 'discount' // 'normal' | 'countdown' | 'discount'

export const landingPage = 'https://thinka.hk' // For share on FB

export const enableCategories = true

export const enableSignup = true

// Switch these 2 off when submitting on app store
export const enableSocialSignin = true
export const enableFileUpload = true
