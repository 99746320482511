import React from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import { useForm, Controller } from 'react-hook-form'
import { styled } from '@material-ui/core/styles'
import get from 'lodash/get'
import Button from '@material-ui/core/Button'
import { useFirebase } from 'react-redux-firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import useI18n from '../../misc/languages/useI18n'
import { enableSignup, enableSocialSignin } from '../../config'

const Form = styled((props) => <form {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
}))

const Input = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const SignInDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  width: '100%',
}))

const FacebookButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: '#3c5898',
  color: 'white',
  '&:hover': {
    backgroundColor: '#29487d',
  },
}))

const GoogleButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#DB4437',
  color: 'white',
  '&:hover': {
    backgroundColor: '#C83830',
  },
}))

const SignInForm = ({ onSignUp, onForgotPassword }) => {
  const firebase = useFirebase()
  const { t } = useI18n()
  const [loading, setLoading] = React.useState(!!window.localStorage.getItem('logging_in'))
  const { handleSubmit, control, errors, setError } = useForm({ reValidateMode: 'onSubmit' })
  const onSubmit = async (credentials) => {
    if (get(credentials, 'provider') && window.ReactNativeWebView) {
      return window.ReactNativeWebView.postMessage(`logging_in::${get(credentials, 'provider')}`)
    }
    try {
      setLoading(true)
      window.localStorage.setItem('logging_in', true)
      let credential
      if (credentials.idToken) {
        credential = firebase.auth.GoogleAuthProvider.credential(credentials.idToken)
      }
      if (credentials.token) {
        credential = firebase.auth.FacebookAuthProvider.credential(credentials.token)
      }
      const { user } = await firebase.login(credential ? { credential } : credentials)
      // TODO: its ok not to verify email for now to speed up user registrations
      // if (!user.user.emailVerified) {
      //   await firebase.auth().currentUser.sendEmailVerification()
      //   setLoading(false)
      //   setError('email', 'not-verified', 'Please check your inbox to verify your email')
      // }
    } catch (err) {
      console.log(err)
      if (err.code === 'auth/too-many-requests') {
        setError('password', 'too-many-requests', t('too many request'))
      } else {
        setError('password', 'incorrect-password', t('password or email incorrect'))
      }
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.login = onSubmit
    }
  }, [])

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={Input}
          name="email"
          label={t('email')}
          control={control}
          defaultValue=""
          error={!!errors.email}
          helperText={get(errors, 'email.message')}
          rules={{
            required: true,
            pattern: {
              value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: t('invalid email'),
            },
          }}
        />
        <Controller
          as={Input}
          name="password"
          type="password"
          label={t('password')}
          control={control}
          defaultValue=""
          error={!!errors.password}
          helperText={get(errors, 'password.message')}
          rules={{
            required: true,
            minLength: {
              value: 6,
              message: t('invalid password'),
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Link onClick={onForgotPassword}>{t('forgot password')}</Link>
              </InputAdornment>
            ),
          }}
        />
        <Box display="flex">
          {enableSignup ? (
            <Button fullWidth color="primary" onClick={onSignUp}>
              {t('sign up')}
            </Button>
          ) : null}
          <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : t('sign in')}
          </Button>
        </Box>
      </Form>
      {enableSocialSignin && enableSignup ? (
        <>
          <SignInDivider />
          <FacebookButton
            fullWidth
            type="submit"
            variant="contained"
            startIcon={
              <img src={require('../../assets/images/facebook.svg')} width={20} height={20} />
            }
            onClick={() =>
              onSubmit({
                provider: 'facebook',
                type: 'popup',
              })
            }
          >
            {t('sign in with facebook')}
          </FacebookButton>
          <GoogleButton
            fullWidth
            type="submit"
            variant="contained"
            startIcon={
              <img src={require('../../assets/images/google.svg')} width={20} height={20} />
            }
            onClick={() =>
              onSubmit({
                provider: 'google',
                type: 'popup',
              })
            }
          >
            {t('sign in with google')}
          </GoogleButton>
        </>
      ) : null}
    </>
  )
}

export default SignInForm
