import React from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useHistory } from 'react-router-dom'
import get from 'lodash/get'
import format from 'date-fns/format'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import Rating from '@material-ui/lab/Rating'
import { useFirestore } from 'react-redux-firebase'
import Button from '@material-ui/core/Button'
import ConfirmDialog from '../../components/ConfirmDialog'
import { useSnackbar } from 'notistack'
import InfoList from '../../components/InfoList'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import { DateTimePicker } from '@material-ui/pickers'
import useI18n from '../../misc/languages/useI18n'
import SmallAvatarWithName from '../../components/SmallAvatarWithName'
import { useSelector } from 'react-redux'

const OverviewTab = ({ assignment, submissions, isMobile }) => {
  const firestore = useFirestore()
  const history = useHistory()
  const { t, locale } = useI18n()
  const { enqueueSnackbar } = useSnackbar()
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 4)
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = React.useState(false)
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false)
  const isSchool = useSelector((state) => state.firebase.profile.isSchool)

  const percentage =
    submissions.map((s) => s.score).reduce((a, b) => a + b, 0) /
    submissions.length /
    assignment.noOfQuestions

  const grade =
    get(assignment, 'subject.grades', []).find((g) => percentage >= g.min && percentage <= g.max) ||
    {}
  const averageTimeSpent =
    submissions
      .map((s) => differenceInMinutes(s.finishedAt, s.createdAt))
      .reduce((a, b) => a + b, 0) / submissions.length

  const infoListItems = [
    isSchool
      ? {
          title: t('teacher'),
          label: (
            <Box mt={1} mb={3}>
              <SmallAvatarWithName
                subtitle={get(assignment, 'teacher.schoolProfile.displayName', '')}
                profile={get(assignment, 'teacher.teacherProfile', {})}
              />
            </Box>
          ),
        }
      : null,
    {
      title: t('created at'),
      label: (
        <Box display="flex">
          <Typography>{format(assignment.createdAt, 'yyyy-MM-dd, hh:mm a')}</Typography>
          <Box ml={1} mt={-0.5}>
            <IconButton size="small" color="primary" onClick={() => setIsDatePickerOpen(true)}>
              <EditIcon />
            </IconButton>
          </Box>
          <DateTimePicker
            style={{ display: 'none' }}
            name="createdAt"
            format="yyyy-MM-dd, hh:mm a"
            label={t('due date')}
            value={assignment.createdAt}
            // disablePast
            fullWidth
            clearable
            open={isDatePickerOpen}
            onClose={() => setIsDatePickerOpen(false)}
            onChange={(d) =>
              firestore.update(
                { collection: 'assignments', doc: assignment.id },
                { createdAt: d.getTime() }
              )
            }
          />
        </Box>
      ),
    },
    {
      title: t('due date'),
      label: (
        <Box display="flex">
          <Typography>{format(assignment.dueDate, 'yyyy-MM-dd, hh:mm a')}</Typography>
          <Box ml={1} mt={-0.5}>
            <IconButton size="small" color="primary" onClick={() => setIsDatePickerOpen(true)}>
              <EditIcon />
            </IconButton>
          </Box>
          <DateTimePicker
            style={{ display: 'none' }}
            name="dueDate"
            format="yyyy-MM-dd, hh:mm a"
            label={t('due date')}
            value={assignment.dueDate}
            // disablePast
            fullWidth
            clearable
            open={isDatePickerOpen}
            onClose={() => setIsDatePickerOpen(false)}
            onChange={(d) =>
              firestore.update(
                { collection: 'assignments', doc: assignment.id },
                { dueDate: d.getTime() }
              )
            }
          />
        </Box>
      ),
    },
    {
      title: t('subject'),
      label: get(assignment, `subject.title.${locale}`),
    },
    {
      title: t('no of questions'),
      label: assignment.noOfQuestions,
    },
    {
      title: t('time limit'),
      label: `${assignment.timeLimit} ${t('minutes')}`,
    },
    {
      title: t('difficulty'),
      label: <Rating name="difficulty" size="large" value={assignment.difficulty} readOnly />,
    },
    {
      title: t('average time spent'),
      label: submissions.length ? `${averageTimeSpent} ${t('minutes')}` : '/',
    },
    {
      title: t('average score'),
      label: submissions.length
        ? `${Math.round(percentage * assignment.noOfQuestions * 10) / 10} / ${
            assignment.noOfQuestions
          } (${Math.round(100 * percentage)}%)`
        : '/',
    },
    {
      title: t('average grade'),
      label: submissions.length ? grade.grade : '/',
      labelProps: {
        variant: 'h3',
        style: { color: grade.color },
      },
    },
  ].filter((a) => a)

  return (
    <>
      <InfoList
        items={infoListItems}
        footer={
          <>
            <Box my={2}>
              <TextField
                fullWidth
                multiline
                label={t('remark')}
                defaultValue={assignment.remark}
                onBlur={(e) => {
                  firestore.update(
                    { collection: 'assignments', doc: assignment.id },
                    { remark: e.target.value }
                  )
                  enqueueSnackbar(t('remark updated'), { key: assignment.id })
                }}
              />
            </Box>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => setConfirmDeleteDialogOpen(true)}
            >
              {t('delete assignment')}
            </Button>
          </>
        }
        height={isMobile ? undefined : minAvailableScreenHeight}
      />
      <ConfirmDialog
        isVisible={confirmDeleteDialogOpen}
        onClose={() => setConfirmDeleteDialogOpen(false)}
        message={t('confirm delete assignment')}
        onSubmit={() => {
          firestore.update({ collection: 'assignments', doc: assignment.id }, { deleted: true })
          enqueueSnackbar(t('assignment deleted'), { key: assignment.id })
          history.replace('/app/assignments')
        }}
      />
    </>
  )
}

export default OverviewTab
