import React from 'react'
import WithAppBar from '../../components/WithAppBar'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import { useSelector } from 'react-redux'
import AdvancedTable from '../../components/AdvancedTable'
import { selectStudents, selectIsFreeTrial } from '../../misc/selectors'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import ButtonBase from '../../components/ButtonBase'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import get from 'lodash/get'
import { useFirestore } from 'react-redux-firebase'
import ConfirmDialog from '../../components/ConfirmDialog'
import SmallAvatarWithName from '../../components/SmallAvatarWithName'
import InviteStudentModal from '../../components/InviteStudentModal'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import format from 'date-fns/format'
import useI18n from '../../misc/languages/useI18n'
import PremiumDialog from '../../components/PremiumDialog'

const MyStudents = () => {
  const firestore = useFirestore()
  const history = useHistory()
  const theme = useTheme()
  const { t } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { height: minAvailableScreenHeight } = useMinAvailableSize(['#app-bar', '#tab-bar'], 4)
  const students = useSelector(selectStudents)
  const { premium, isSchool } = useSelector((state) => state.firebase.profile || {})
  const isFreeTrial = useSelector(selectIsFreeTrial)
  const shouldShowSchoolSelector = students.find((s) => !!s.school)
  const [deletingStudent, setDeletingStudent] = React.useState(null)
  const [inviting, setInviting] = React.useState(false)
  const [isPremiumDialogVisible, setIsPremiumDialogVisible] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const columns = [
    {
      key: 'displayName',
      label: t('name'),
    },
    {
      key: 'pendingAssignments',
      label: t('on going assignments'),
    },
    {
      key: 'lateAssignments',
      label: t('late'),
    },
    {
      key: 'totalAssignments',
      label: t('total'),
    },
    {
      key: 'punctuality',
      label: t('punctuality'),
    },
    // {
    //   key: 'createdAt',
    //   label: t('created at'),
    // },
    {
      key: 'actions',
      label: '',
      disableSort: true,
    },
  ]
  const rows = students.map((b) => ({
    key: b.id,
    onClick: () =>
      history.push(`/app/statistics/${b.student}${b.school ? `?school=${b.school}` : ''}`),
    profile: b.studentProfile,
    accepted: b.accepted,
    school: {
      id: b.school || '',
      ...(b.schoolProfile || {}),
    },
    displayName: {
      label: <SmallAvatarWithName profile={b.studentProfile} />,
      sortValue: get(b, 'studentProfile.displayName', ''),
      searchValue: get(b, 'studentProfile.displayName', ''),
    },
    pendingAssignments: {
      label: b.accepted ? (
        b.pendingAssignments
      ) : (
        <Typography color="textSecondary">{t('invitation sent pending acceptance')}</Typography>
      ),
      sortValue: b.accepted ? b.pendingAssignments : -1,
      searchValue: b.accepted
        ? b.pendingAssignments.toString()
        : t('invitation sent pending acceptance'),
    },
    lateAssignments: {
      label: b.accepted ? `${b.lateAssignments}` : '',
      sortValue: b.lateAssignments,
    },
    totalAssignments: {
      label: b.accepted ? `${b.totalAssignments}` : '',
      sortValue: b.totalAssignments,
    },
    punctuality: {
      label: b.accepted
        ? `${
            Math.round((100 * (b.totalAssignments - b.lateAssignments)) / b.totalAssignments) || 0
          }%`
        : '',
      sortValue: (b.totalAssignments - b.lateAssignments) / b.totalAssignments,
    },
    createdAt: {
      label: format(b.createdAt, 'yyyy-MM-dd'),
      sortValue: b.createdAt,
    },
    actions: {
      label: (
        <Box display="flex" my={-2}>
          <Button
            color="secondary"
            onClick={(e) => {
              e.stopPropagation()
              setDeletingStudent(b.id)
            }}
          >
            {t('remove')}
          </Button>
        </Box>
      ),
      searchValue: '',
    },
  }))

  const defaultSchool = get(
    rows.find((r) => !r.accepted),
    'school.id'
  )

  const onAdd = () =>
    !isFreeTrial && get(premium, 'student.quantity', 0) <= students.length && isSchool
      ? setIsPremiumDialogVisible(true)
      : setInviting(true)

  return (
    <WithAppBar
      title={t('my students')}
      rightButtonIcon={isMobile ? <AddIcon /> : undefined}
      onRightButtonClick={onAdd}
    >
      <AdvancedTable
        withSchoolFilter={shouldShowSchoolSelector}
        defaultSchool={defaultSchool}
        containerProps={{ height: minAvailableScreenHeight }}
        rows={rows}
        columns={columns}
        defaultSortBy="createdAt"
        defaultSortOrder={false}
        onAdd={isMobile ? undefined : onAdd}
        renderMobileItem={(row) => (
          <ButtonBase key={row.key} onClick={row.onClick}>
            <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
              <SmallAvatarWithName
                moreMargin
                profile={row.profile}
                subtitle={
                  row.accepted ? (
                    <>
                      <Typography variant="body2" color="textSecondary">
                        {t('on going')}: {row.pendingAssignments.label} | {t('late')}:{' '}
                        {row.lateAssignments.label} | {t('total')}: {row.totalAssignments.label}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {t('punctuality')}: {row.punctuality.label}
                      </Typography>
                    </>
                  ) : (
                    row.pendingAssignments.label
                  )
                }
              />
              {row.actions.label}
            </Box>
            <Divider />
          </ButtonBase>
        )}
        emptyText={t('no student')}
      />
      <ConfirmDialog
        isVisible={!!deletingStudent}
        onClose={() => setDeletingStudent(null)}
        message={t('confirm remove student')}
        onSubmit={() => {
          firestore.update({ collection: 'students', doc: deletingStudent }, { deleted: true })
          enqueueSnackbar(t('student removed'), { key: deletingStudent })
          setDeletingStudent(null)
        }}
      />
      <InviteStudentModal isVisible={inviting} onClose={() => setInviting(false)} />
      <PremiumDialog
        isVisible={isPremiumDialogVisible}
        onClose={() => setIsPremiumDialogVisible(false)}
      />
    </WithAppBar>
  )
}

export default MyStudents
