import React from 'react'
import { useFirestore } from 'react-redux-firebase'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import CorrectIcon from '@material-ui/icons/Check'
import WrongIcon from '@material-ui/icons/Clear'
import HTMLContent from '../HTMLContent'
import BookmarkButton from '../BookmarkButton'
import { green, red } from '@material-ui/core/colors'
import { selectBookmark, selectChapter } from '../../misc/selectors'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import ChangeIcon from '@material-ui/icons/Loop'
import ReportIcon from '@material-ui/icons/Report'
import Rating from '@material-ui/lab/Rating'
import useI18n from '../../misc/languages/useI18n'

const QuestionWithAnswersCard = ({
  question,
  questionNo,
  practiceId,
  assignmentId,
  correctAns,
  ans,
  withFooter,
  noStat,
  onReportQuestion,
  onChangeQuestion,
  noBookmark,
  dense,
  withSolution,
  ...props
}) => {
  const { t, locale } = useI18n()
  const firestore = useFirestore()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const uid = useSelector((state) => get(state, 'firebase.auth.uid'))
  const chapter = useSelector((state) => selectChapter(state, get(question, 'chapter')))

  const bookmark = useSelector((state) =>
    selectBookmark(state, practiceId, assignmentId, questionNo)
  )

  const totalStat =
    ['a', 'b', 'c', 'd', 'blank']
      .map((a) => get(question, `stat.${a}`, 0))
      .reduce((a, b) => a + b, 0) || 1

  return chapter ? (
    <Box
      m={2}
      p={2}
      borderRadius="borderRadius"
      boxShadow={3}
      bgcolor="background.paper"
      overflow="auto"
      maxWidth={`calc(100vw - ${theme.spacing(4)}px)`}
      {...props}
    >
      {isMobile ? (
        <Box mb={2}>
          <Typography variant="subtitle2">
            {t('chapter')} {get(chapter, 'chapterNo', '')}: {get(chapter, `title.${locale}`, '')}
          </Typography>
        </Box>
      ) : null}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" alignItems="flex-end">
          <Box mr={2}>
            <Typography variant="h6" color="primary">
              {questionNo + 1}.
            </Typography>
          </Box>
          {isMobile || dense ? null : (
            <Box mr={6}>
              <Typography variant="subtitle1">
                {t('chapter')} {get(chapter, 'chapterNo', '')}:{' '}
                {get(chapter, `title.${locale}`, '')}
              </Typography>
            </Box>
          )}
          {dense ? null : (
            <>
              <Typography variant="subtitle1">{t('level')}:</Typography>
              <Box mb={-0.2}>
                <Rating
                  max={3}
                  unselectable="on"
                  value={Number(get(question, 'level', 0))}
                  readOnly
                />
              </Box>
            </>
          )}
        </Box>
        {noBookmark ? null : (
          <BookmarkButton
            value={bookmark ? 1 : 0}
            onChange={(e, v) => {
              bookmark
                ? firestore.delete({ collection: 'bookmarks', doc: bookmark.id })
                : firestore.add('bookmarks', {
                    user: uid,
                    practice: practiceId || '',
                    assignment: assignmentId || '',
                    questionNo,
                    createdAt: Date.now(),
                    chapter: get(question, 'chapter'),
                    remark: '',
                  })
            }}
          />
        )}
      </Box>

      <Box mb={4}>
        <HTMLContent html={get(question, `question.${locale}`, '')} />
      </Box>
      <Grid container>
        {['a', 'b', 'c', 'd'].map((a) => {
          const content = get(question, `${a}.${locale}`, '')
          if (!content) {
            return null
          }
          return (
            <Grid
              key={`${get(question, 'id', '')}_${a}`}
              item
              xs={dense ? 6 : 12}
              sm={dense ? 6 : 12}
              md={6}
            >
              <Box mb={4} display="flex" justifyContent="space-between">
                <Box display="flex">
                  <Box mr={2} position="relative">
                    <Typography>{a.toUpperCase()}. </Typography>
                    {ans === a ? (
                      <Box
                        position="absolute"
                        top={-8}
                        left={-12}
                        p={2}
                        border={2}
                        borderColor={correctAns === a ? green[500] : red[500]}
                        borderRadius="50%"
                      />
                    ) : null}
                  </Box>
                  <HTMLContent html={content} />
                </Box>
                <Box display="flex">
                  {correctAns === a ? (
                    <Box mr={noStat ? (isMobile ? 0 : 8) : 4}>
                      <CorrectIcon style={{ color: green[500] }} />
                    </Box>
                  ) : ans === a ? (
                    <Box mr={noStat ? (isMobile ? 0 : 8) : 4}>
                      <WrongIcon style={{ color: red[500] }} />
                    </Box>
                  ) : null}
                  {noStat ? null : (
                    <Box mr={isMobile ? 0 : 8}>
                      <Typography color="textSecondary">
                        {Math.round((100 * get(question, `stat.${a}`, 0)) / totalStat)}%
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          )
        })}
      </Grid>
      {noStat ? null : (
        <Typography>
          <Typography component="span" color="textSecondary">
            {Math.round((100 * get(question, `stat.blank`, 0)) / totalStat)}%{' '}
          </Typography>
          {t('leave it blank')}
        </Typography>
      )}
      {withSolution ? (
        <>
          <Typography variant="h6" gutterBottom>
            {t('solution')}
          </Typography>
          <HTMLContent html={get(question, `solution.${locale}`, '')} />
        </>
      ) : null}
      {withFooter ? (
        <Box m={2} display="flex" justifyContent={isMobile ? 'center' : 'flex-end'}>
          <Button
            style={{ width: 120 }}
            color="secondary"
            onClick={() => onReportQuestion(question)}
            startIcon={<ReportIcon />}
          >
            {t('report')}
          </Button>
          <Button
            style={{ width: 120 }}
            color="primary"
            onClick={() => onChangeQuestion(question)}
            startIcon={<ChangeIcon />}
          >
            {t('change')}
          </Button>
        </Box>
      ) : null}
    </Box>
  ) : null
}

export default QuestionWithAnswersCard
