import React from 'react'
import AdvancedTable from '../../components/AdvancedTable'
import useMinAvailableSize from '../../misc/useMinAvailableSize'
import get from 'lodash/get'
import SmallAvatarWithName from '../../components/SmallAvatarWithName'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useHistory } from 'react-router-dom'
import format from 'date-fns/format'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import ButtonBase from '../../components/ButtonBase'
import { useSelector } from 'react-redux'
import useI18n from '../../misc/languages/useI18n'

const StudentsTab = ({ assignment, submissions }) => {
  const theme = useTheme()
  const history = useHistory()
  const { t } = useI18n()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true })
  const { height: tabContentSizeWithoutBottomSpace } = useMinAvailableSize(
    ['#app-bar', '#tab-bar', '#tabs-bar'],
    6
  )
  const tabContentSize =
    tabContentSizeWithoutBottomSpace - get(window, 'ReactNativeWebView.bottomSpace', 0)

  const columns = [
    {
      key: 'student',
      label: t('student'),
    },
    // {
    //   key: 'finishedAt',
    //   label: t('submit time'),
    // },
    {
      key: 'timeSpent',
      label: t('time spent'),
    },
    {
      key: 'performance',
      label: t('performance'),
    },
    {
      key: 'rank',
      label: t('rank'),
    },
  ]

  const rows = assignment.students
    .map((student, i) => {
      const submission = submissions.find((s) => s.user === student.student)
      const score = submission ? submission.score : 0
      return {
        key: get(student, 'student', ''),
        submission,
        score,
        count: assignment.noOfQuestions,
        profile: get(student, 'studentProfile', {}),
        onClick: submission
          ? () =>
              history.push(
                `/app/assignment-submission/${assignment.id}/${get(student, 'student', '')}/${i}`
              )
          : null,
        student: {
          label: <SmallAvatarWithName profile={get(student, 'studentProfile', {})} />,
          sortValue: get(student, 'studentProfile.displayName', ''),
          searchValue: get(student, 'studentProfile.displayName', ''),
        },
        finishedAt: {
          label: submission
            ? format(submission.finishedAt, 'yyyy-MM-dd, hh:mm a')
            : t('not submitted yet'),
          sortValue: get(submission, 'finishedAt', Date.now()),
          color:
            get(submission, 'finishedAt', Date.now()) > assignment.dueDate
              ? 'error'
              : 'textPrimary',
        },
        timeSpent: {
          sortValue: submission
            ? differenceInMinutes(submission.finishedAt, submission.createdAt)
            : 0,
          label: submission
            ? `${differenceInMinutes(submission.finishedAt, submission.createdAt)} ${t('minutes')}`
            : '',
        },
        performance: {
          label: submission
            ? `${score} / ${assignment.noOfQuestions} (${
                Math.round((100 * score) / assignment.noOfQuestions) || 0
              }%)`
            : '',
          sortValue: score / assignment.noOfQuestions,
          color: get(
            get(assignment, 'subject.grades', []).find(
              (g) =>
                (score / assignment.noOfQuestions || 0) >= g.min &&
                (score / assignment.noOfQuestions || 0) <= g.max
            ),
            'color'
          ),
        },
      }
    })
    .sort((a, b) => get(b, 'score.sortValue') - get(a, 'score.sortValue'))
    .map((r, i) => ({
      ...r,
      rank: {
        label: r.submission ? (i + 1).toString() : '',
      },
    }))

  return (
    <Box pt={2} pb={isMobile ? 2 : 0}>
      <AdvancedTable
        containerProps={{
          pb: get(window, 'ReactNativeWebView.bottomSpace', 0) + 'px',
          mb: 0,
          mt: 0,
          ml: isMobile ? 2 : 0,
          height: isMobile ? undefined : tabContentSize,
          minHeight: isMobile ? tabContentSize : undefined,
        }}
        columns={columns}
        rows={rows}
        defaultSortBy="rank"
        defaultSortOrder
        renderMobileItem={(row) => (
          <ButtonBase key={row.key} onClick={row.onClick}>
            <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
              <SmallAvatarWithName
                moreMargin
                profile={row.profile}
                subtitle={
                  <>
                    <Typography
                      variant="body2"
                      color={row.finishedAt.color.replace('Primary', 'Secondary')}
                    >
                      {row.finishedAt.label}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t('time spent')}: {row.timeSpent.label || '?'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t('rank')}: {row.rank.label || '?'} | {t('performance')}:{' '}
                      {row.submission ? `${row.score} / ${row.count}` : '?'}
                    </Typography>
                  </>
                }
              />
              <Typography variant="h5" style={{ color: row.performance.color }}>
                {Math.round((100 * row.score) / row.count)}%
              </Typography>
            </Box>
            <Divider />
          </ButtonBase>
        )}
        emptyText={t('no student')}
      />
    </Box>
  )
}

export default StudentsTab
